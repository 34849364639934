import { NavLink } from "react-router-dom";
import { NavigationOptions } from "./navigationManager";

interface NavigationLinkProps extends NavigationOptions {
  active: boolean;
  closeMenu: () => void;
}

const NavigationLink: React.FC<NavigationLinkProps> = ({
  label,
  path,
  icon,
  active,
  closeMenu,
}) => {
  const handleClick = () => {
    if (active) {
      closeMenu();
    }
  };
  return (
    <>
      <NavLink
        to={path}
        className="flex justify-between items-center cursor-pointer nav-item bm-item"
        onClick={handleClick}
      >
        <div className="flex items-center">
          {icon && <div className="mr-4">{icon}</div>}
          <span>{label}</span>
        </div>
      </NavLink>
    </>
  );
};
export default NavigationLink;
