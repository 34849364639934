import React from "react";
import "../../assets/stylesheets/timeline.css";

interface TimelineItemProps {
  imageSrc: string;
  link: string;
  startDate: Date;
  endDate: Date | undefined;
  companyName: string;
  position: string;
}

interface TimelineProps {
  items: TimelineItemProps[];
}

const Timeline: React.FC<TimelineProps> = ({ items }) => (
  <div className="table">
    {items
      .slice()
      .reverse()
      .map((item, index) => (
        <div className="timelineContainer" key={index}>
          <div className="imageWrapper">
            <img src={item.imageSrc} title={item.position} className="logo" />
            <a
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              className="logoBorder"
            />
            <div className="line" />
            <div className="pointer" />
          </div>
          <div className="textWrapper">
            <h1 className="primaryTitle text-lightskyblue">{item.position}</h1>
            <a
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              className="secondaryTitle text-white"
            >
              {item.companyName}
            </a>
            <p className="text-white">
              {item.startDate.toLocaleString("en-US", {
                month: "numeric",
                year: "numeric",
              })}{" "}
              -{" "}
              {item.endDate
                ? item.endDate.toLocaleString("en-US", {
                    month: "numeric",
                    year: "numeric",
                  })
                : "PRESENT"}
            </p>
          </div>
        </div>
      ))}
  </div>
);

export default Timeline;
