import { getTotalGithubRepositoryCount } from "api/github";
import Sidebar from "components/navigation/sidebar";
import GithubProjectList from "components/projects/githubProjectList";
import Particle from "components/util/particles";
import { GITHUBPROJECTS } from "data";
import { useEffect, useState } from "react";

const Projects: React.FC = () => {
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    const fetchCount = async () => {
      const data = await getTotalGithubRepositoryCount();
      setCount(data);
    };
    fetchCount();
  }, []);

  return (
    <>
      <Particle />
      <Sidebar />
      <div className="page-container">
        <GithubProjectList
          projects={GITHUBPROJECTS}
          totalGithubProjects={count}
        />
      </div>
    </>
  );
};
export default Projects;
