interface ButtonProperties {
  text: string;
  link: string;
  className?: string;
}

const Button: React.FC<ButtonProperties> = ({ link, text, className = "" }) => (
  <a href={link} target="_blank" className={className}>
    {text}
  </a>
);

export default Button;
