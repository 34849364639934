import { createBrowserRouter, RouterProvider } from "react-router-dom";
import RedirectTo from "components/util/redirectRoute";
import Experience from "./pages/experience";
import Projects from "pages/projects";
import { Helmet } from "react-helmet";
import Routes from "./constants/routes";

const router = createBrowserRouter([
  { path: Routes.experience, Component: Experience },
  { path: Routes.projects, Component: Projects },
  { path: "/*", Component: () => <RedirectTo to="/experience" /> },
]);

const App = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Dario Bronders | Software Engineer</title>
    </Helmet>
    <RouterProvider router={router} />;
  </>
);

export default App;
