import React from "react";

interface ExtraProjectsCardProps {
  count: number;
  link: string;
}

const ExtraProjectsCard: React.FC<ExtraProjectsCardProps> = ({
  count,
  link,
}) => {
  return (
    <div className="shadow-lg rounded-lg m-5 bg-particles relative">
      <div className="p-4 mt-3 mr-3">
        <p className="text-2xl text-white">
          {count} more public codebases can be found on my{" "}
          <a href={link} className="text-lightskyblue" target="_blank">
            profile.
          </a>
        </p>
      </div>
    </div>
  );
};

export default ExtraProjectsCard;
