import { TypeAnimation } from "react-type-animation";

interface Props {
  messages: Array<string>;
  delay?: number;
  repeat?: number;
  className?: string;
}
const AutoTyper: React.FC<Props> = ({
  messages,
  delay = 2500,
  repeat = Infinity,
  className = "",
}) => (
  <TypeAnimation
    sequence={messages.flatMap((e) => [e, delay])}
    speed={50}
    repeat={repeat}
    className={className}
    color="#111111"
  />
);

export default AutoTyper;
