import weAreLogo from "./assets/images/weAreLogo.png";
import cermLogo from "./assets/images/cermLogo.webp";
import hogentLogo from "./assets/images/hogentLogo.png";
import typescriptLogo from "./assets/images/typescriptLogo.svg";
import pythonLogo from "./assets/images/pythonLogo.png";
import csharpLogo from "./assets/images/csharpLogo.svg";
import javascriptLogo from "./assets/images/javascriptLogo.png";
import { GithubProjectCardProps } from "components/projects/githubProjectCard";

export const EXPERIENCES = [
  {
    imageSrc: hogentLogo,
    link: "https://www.hogent.be/",
    startDate: new Date(2019, 8),
    endDate: new Date(2023, 8),
    companyName: "HoGent",
    position: "Computer Science: Mobile & Enterprise Developer",
  },
  {
    imageSrc: weAreLogo,
    link: "https://www.we-are.be/",
    startDate: new Date(2023, 1),
    endDate: new Date(2023, 4),
    companyName: "we are technology",
    position: "Full Stack Software Engineer Intern",
  },
  {
    imageSrc: cermLogo,
    link: "https://www.cerm.net",
    startDate: new Date(2023, 6),
    endDate: undefined,
    companyName: "Cerm",
    position: "Full Stack Software Engineer",
  },
];

export const GITHUBPROJECTS: Array<GithubProjectCardProps> = [
  {
    title: "Quiz Webapplication",
    image: javascriptLogo,
    description:
      "The frontend made in react for the quiz application. Users can submit new quizes or play quizes to compete on the leaderboard.",
    srcLink: "https://github.com/Darjow/QuizApp-Frontend-React",
  },
  {
    title: "Quiz Webserver",
    image: javascriptLogo,
    description: "The backend made in Node.js for the quiz application.",
    srcLink: "https://github.com/Darjow/QuizApp-Backend-Node",
  },
  {
    title: "React Typescript Portfolio",
    image: typescriptLogo,
    description:
      "The Website you're currently browsing is made using the react framework and is written in Typescript.",
    srcLink: "https://github.com/Darjow/Portfolio-ts",
    privateRepo: true,
  },
  {
    title: "AI Art News Integration",
    image: pythonLogo,
    description:
      "A console application that will analyze the current news articles and determine the most important article with the help of webscraping. It will then generate an artpiece based of the highlight of the day.",
    srcLink: "https://github.com/Darjow/AI-Art-News-Integration",
  },
  {
    title: "Blazor virtual IT Company",
    image: csharpLogo,
    description: "A Blazor WASM project. Made during college",
    srcLink: "https://github.com/Darjow/blazor-virtual-it-company",
  },
];
