import "../../assets/stylesheets/sidebar.css";
import Routes from "constants/routes";
import NavigationManager from "./navigationManager";

const Sidebar = () => (
  <NavigationManager
    options={[
      { path: Routes.experience, label: "01 EXPERIENCE" },
      { path: Routes.projects, label: "02 PROJECTS" },
    ]}
  />
);

export default Sidebar;
