import React from "react";

export interface GithubProjectCardProps {
  title: string;
  image: string;
  description: string;
  srcLink?: string;
  privateRepo?: boolean;
}

const GithubProjectCard: React.FC<GithubProjectCardProps> = ({
  title,
  image,
  srcLink,
  description,
  privateRepo = false,
}) => {
  console.log(privateRepo);

  return (
    <div className="shadow-lg rounded-lg bg-particles relative m-5 hover:scale-110 transition-transform duration-300">
      <img src={image} alt={title} className="absolute top-2 right-2 w-8" />
      <div className="p-4 mt-3 mr-3">
        <h1 className="primaryTitle text-lightskyblue text-3xl mb-2">
          {title}
        </h1>
        <p className="text-2xl mb-5 text-white">{description}</p>
        {srcLink && !privateRepo && (
          <a
            href={srcLink}
            className="text-lg font-black text-lightskyblue py-2 px-4 absolute bottom-0 left-0 w-full"
            target="_blank"
          >
            Source Code
          </a>
        )}
      </div>
    </div>
  );
};

export default GithubProjectCard;
