import ExperienceIntroduction from "components/experience/introduction";
import Timeline from "components/experience/timeline";
import Sidebar from "components/navigation/sidebar";
import Particle from "components/util/particles";
import { EXPERIENCES } from "../data";

const Experience = () => (
  <>
    <Particle />
    <Sidebar />
    <div className="page-container">
      <div className="xl:flex">
        <div className="xl:w-1/2 xl:text-start w-full text-center">
          <ExperienceIntroduction />
        </div>
        <div className="xl:w-1/2 w-full text-center">
          <Timeline items={EXPERIENCES} />
        </div>
      </div>
    </div>
  </>
);

export default Experience;
