import React, { useState } from "react";
import NavigationLink from "./navigationLink";
import { slide as Menu } from "react-burger-menu";
import { useLocation } from "react-router";

export interface NavigationOptions {
  path: string;
  icon?: React.ReactNode;
  label: string;
}

interface NavigationManagerProps {
  options: NavigationOptions[];
}
const NavigationManager: React.FC<NavigationManagerProps> = ({ options }) => {
  const [isOpen, setIsOpen] = useState(false);

  const url = useLocation().pathname.split("/").pop();
  const current = options.filter((e) => e.path.split("/")[1] === url)[0];

  const closeMenu = () => {
    setIsOpen(false);
  };
  const handleMenuStateChange = (state: { isOpen: boolean }) => {
    setIsOpen(state.isOpen);
  };

  return (
    <nav className="flex flex-row justify-between h-5-rem sticky z-10 navbar">
      <div>
        <Menu isOpen={isOpen} onStateChange={handleMenuStateChange}>
          {options.map((option) => (
            <NavigationLink
              {...option}
              key={option.path}
              active={option === current}
              closeMenu={closeMenu}
            />
          ))}
        </Menu>
      </div>
      <div className="flex align-center mt-6 invisible md:flex md:visible">
        <div key={current.path}>
          <h1 className="mr-10 text-5xl">
            <span className="font-extrabold">
              {current.label.split(" ")[1] + " "}
            </span>
            <span>{current.label.split(" ")[0]}</span>
          </h1>
        </div>
      </div>
    </nav>
  );
};

export default NavigationManager;
