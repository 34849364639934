import AutoTyper from "components/util/autotyper";
import Button from "components/util/button";

const ExperienceIntroduction = () => (
  <div>
    <h1 className="text-6xl font-bold">Dario Bronders</h1>
    <h2 className="text-4xl">Software Engineer</h2>
    <div className="text-3xl break-words mb-5">
      <AutoTyper
        messages={[
          "Perfectionism, Critical Thinking, Exploring, Curious",
          "Interested in automation.",
        ]}
      />
    </div>
    <Button
      link="https://www.linkedin.com/in/dario-bronders-48485823a/"
      text="Go to LinkedIn"
      className="inline-block text-3xl font-light py-3 px-6 no-underline bg-black text-white hover:bg-white hover:text-black hover:border-none"
    />
  </div>
);

export default ExperienceIntroduction;
