import ExtraProjectsCard from "./extraProjectsCard";
import GithubProjectCard, { GithubProjectCardProps } from "./githubProjectCard";

interface GithubListProps {
  projects: GithubProjectCardProps[];
  totalGithubProjects: number;
}

const githubProjectList: React.FC<GithubListProps> = ({
  projects,
  totalGithubProjects,
}) => {
  return (
    <>
      <h1 className="font-black text-6xl">Github</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
        {projects.map((project, index) => (
          <GithubProjectCard key={index} {...project} />
        ))}
        <ExtraProjectsCard
          count={totalGithubProjects - projects.length}
          link="https://github.com/Darjow/"
        />
      </div>
    </>
  );
};

export default githubProjectList;
