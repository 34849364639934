const USERNAME = "Darjow";

export async function getTotalGithubRepositoryCount(): Promise<number> {
  try {
    const response = await fetch(
      `https://api.github.com/users/${USERNAME}/repos`
    );
    const repositories = await response.json();
    const publicRepositoriesCount = repositories.length;

    return publicRepositoriesCount;
  } catch (error) {
    return 0;
  }
}
